import {
  Component, EventEmitter, Input, OnChanges, OnInit,
  Output, SimpleChange, SimpleChanges, ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {
  Profile,
  Address,
  ProfileAddresses, Payment,
} from '../../../api';
import { ProfilePayments } from '../../models/ProfilePayments';
import { Patterns } from '../../../shared/validation/patterns';
import { EmailChange } from '../../models/emailChange';
import { ProfileChange } from '../../models/profileChange';
import { PasswordChange } from '../../models/passwordChange';
import { PaymentBag } from '../../models/paymentBag';
import { CorrectedAddress } from '../../models/correctedAddress';
import { select, Store } from '@ngrx/store';
import * as fromProfile from "../../reducers/index";
import { Router } from '@angular/router';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnChanges {

  marketingOptIn = false;

  profileForm: UntypedFormGroup;

  editEmailModalRef: NgbModalRef;

  editPasswordModalRef: NgbModalRef;

  editAddressModalRef: NgbModalRef;

  editPaymentModalRef: NgbModalRef;

  passwordResetSubscription: any;

  modalOptions: NgbModalOptions;

  @Input() isMobile: boolean | false;

  @Input() paymentMore = {'show': true, 'hide':false, 'buttonName' : 'View More'};

  @Input() addressMore = {'show': true, 'hide':false, 'buttonName' : 'View More'};

  @Input() isLoggedIn: boolean | false;

  @Input() profile: Profile | null;

  @Input() profileAddresses: ProfileAddresses | null;

  @Input() profilePayments: ProfilePayments | null;

  @Input() pending: boolean | false;

  @Output() submitted = new EventEmitter<ProfileChange>();

  // Email

  @Input() profileEmailChangeError: boolean | false;

  @Input() profileEmailChangePending: boolean | false;

  @Output() profileEmailSubmitted = new EventEmitter<EmailChange>();

  // Password

  @Input() profilePasswordChangeError: boolean | false;

  @Input() profilePasswordChangePending: boolean | false;

  @Input() isPasswordSuccessfullyReset: boolean | false;

  @Input() isVisibile: boolean = true;

  @Output() profilePasswordSubmitted = new EventEmitter<PasswordChange>();


  // Address

  @Input() profileAddressChangePending: boolean | false;

  @Input() profileAddressRemovalPending: boolean | false;

  @Input() profileAddress: Address | null;

  @Input() profileAddressChangeError: boolean | false;

  @Input() profileCorrectedAddress: CorrectedAddress | null;

  @Output() profileAddressOpened = new EventEmitter<Address>();

  @Output() profileAddressSubmitted = new EventEmitter<Address>();

  @Output() suggestedAddressSubmitted = new EventEmitter<Address>();

  @Output() profileAddressRemoved = new EventEmitter<Address>();

  @Output() profileAddressSetPrimary = new EventEmitter<Address>();

  // Payment

  @Input() profilePaymentChangeError: string | null;

  @Input() profilePaymentChangePending: boolean | false;

  @Input() profilePaymentRemovalPending: boolean | false;

  @Input() profilePayment: Payment | null;

  @Output() profilePaymentOpened = new EventEmitter<Payment>();

  @Output() profilePaymentSubmitted = new EventEmitter<PaymentBag>();

  @Output() profilePaymentRemoved = new EventEmitter<Payment>();

  @Output() profilePaymentSetPrimary = new EventEmitter<Payment>();

  // Opted In

  @Output() profileOptedInSubmitted = new EventEmitter<boolean>();

  @ViewChild('successModal', { static: true })
  successModal: any;

  @ViewChild('editEmailModal', { static: true })
  editEmailModal: any;

  @ViewChild('editPasswordModal', { static: true })
  editPasswordModal: any;

  @ViewChild('editAddressModal', { static: true })
  editAddressModal: any;

  @ViewChild('confirmDeleteAddressModal', { static: true })
  confirmDeleteAddressModal: any;

  @ViewChild('alertDeleteAddressModal', { static: true })
  alertDeleteAddressModal: any;

  @ViewChild('editPaymentModal', { static: true })
  editPaymentModal: any;

  @ViewChild('confirmDeletePaymentModal', { static: true })
  confirmDeletePaymentModal: any;

  @ViewChild('alertDeletePaymentModal', { static: true })
  alertDeletePaymentModal: any;

  @ViewChild('passwordChangeConfirmationModal', { static: true })
  passwordChangeConfirmationModal: any;

  @ViewChild('confirmMakePrimaryPaymentModal', {static: true})
  confirmMakePrimaryPaymentModal: any;

  @ViewChild('successPrimaryProfilePaymentModal', { static: true })
  successPrimaryProfilePaymentModal: any;
  defaultPaymentInfo: boolean = false;

  constructor(private fb: UntypedFormBuilder,
              private modalService: NgbModal,
              private store: Store<fromProfile.State>, 
              private router: Router
              ) {

              this.modalOptions = {
                      backdrop : 'static',
                      keyboard : false
                };
      
    this.createForm();
  }

  ngOnInit() {
    this.checkDefaultPaymentInfo();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['profilePayments']) {
      this.checkDefaultPaymentInfo();
    }
    const profileChange: SimpleChange = changes['profile'];
    if (profileChange) {
      this.resetForm();
      this.marketingOptIn = this.profile.optedIn;
    }
    const pendingChange: SimpleChange = changes['pending'];
    if (pendingChange && !pendingChange.firstChange &&
      !pendingChange.currentValue ) {
      this.success();
    }
    // On mobile close any open desktop dialogs
    const isMobileChange: SimpleChange = changes['isMobile'];
    if (isMobileChange && !isMobileChange.firstChange &&
      isMobileChange.currentValue) {
      if (this.editEmailModalRef) {
        this.editEmailModalRef.close();
      }
      if (this.editPasswordModalRef) {
        this.editPasswordModalRef.close();
      }
      if (this.editAddressModalRef) {
        this.editAddressModalRef.close();
      }
      if (this.editPaymentModalRef) {
        this.editPaymentModalRef.close();
      }
    }
    // Changing the email will require the application to login in again,
    // Only after the email is changed and there are not errors we close the desktop dialog
    const isLoggedInChange: SimpleChange = changes['isLoggedIn'];
    if (isLoggedInChange && !isLoggedInChange.firstChange &&
      isLoggedInChange.currentValue) {
      if (this.editEmailModalRef) {
        this.editEmailModalRef.close();
      }
    }
    // Only after the password is changed and there are not errors we close the desktop dialog
    const profilePasswordChangePendingChange: SimpleChange = changes['profilePasswordChangePending'];
    if (profilePasswordChangePendingChange && !profilePasswordChangePendingChange.firstChange &&
      !profilePasswordChangePendingChange.currentValue && !this.profilePasswordChangeError) {
      if (this.editPasswordModalRef) {
        this.editPasswordModalRef.close();
      }
    }
    // Only after getting the profile address we open a modal
    const profileAddressChange: SimpleChange = changes['profileAddress'];
    if (profileAddressChange && !profileAddressChange.firstChange &&
      profileAddressChange.currentValue) {
      // Using timeout because of issue here:
      // https://github.com/ng-bootstrap/ng-bootstrap/issues/1775
      setTimeout(() => {
        this.editAddressModalRef = this.modalService.open(this.editAddressModal, this.modalOptions);
      }, 100);
    }
    // Only after the address is changed and there are not errors we close the desktop dialog
    const profileAddressChangePendingChange: SimpleChange = changes['profileAddressChangePending'];
    if (profileAddressChangePendingChange && !profileAddressChangePendingChange.firstChange &&
      !profileAddressChangePendingChange.currentValue) {
      if (this.editAddressModalRef) {
        this.editAddressModalRef.close();
      }
    }
    // Only after getting the profile payment we open a modal
    const profilePaymentChange: SimpleChange = changes['profilePayment'];
    if (profilePaymentChange && !profilePaymentChange.firstChange &&
      profilePaymentChange.currentValue) {
      // Using timeout because of issue here:
      // https://github.com/ng-bootstrap/ng-bootstrap/issues/1775
      setTimeout(() => {
        this.editPaymentModalRef = this.modalService.open(this.editPaymentModal);
      }, 100);
    }
    // Only after the payment is changed and there are not errors we close the desktop dialog
    const profilePaymentChangePendingChange: SimpleChange = changes['profilePaymentChangePending'];
    if (profilePaymentChangePendingChange && !profilePaymentChangePendingChange.firstChange &&
      !profilePaymentChangePendingChange.currentValue && !this.profilePaymentChangeError) {
      if (this.editPaymentModalRef) {
        this.editPaymentModalRef.close();
      }
    }
    // show success modal after password change
    const passwordSuccessfullyResetChange: SimpleChange = changes['isPasswordSuccessfullyReset'];
    if(passwordSuccessfullyResetChange && !passwordSuccessfullyResetChange.firstChange &&
      passwordSuccessfullyResetChange.currentValue) {
      this.onPasswordUpdatedSuccessfully();
    }
    
  }
  checkDefaultPaymentInfo() {
    if (this.profilePayments && this.profilePayments.payments && this.profile?.hasOrder) {
      // Check if there is any payment with defaultPaymentInfo set to true
      this.defaultPaymentInfo = this.profilePayments.payments.some(payment => payment.defaultPaymentInfo);
    }
  }

  createForm() {
    this.profileForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: [{value: '', disabled: true}, {
        validators: [Validators.required, Validators.pattern(Patterns.emailPattern)]
      }],
      phoneNumber: ['', {
        validators: [Validators.required, Validators.pattern(Patterns.phoneNumberPattern)],
      }],
      phoneType: ['', Validators.required],
      password: [{ value: '', disabled: true }, [Validators.required]],
      optedIn: [false],
    });
  }

  resetForm() {
    this.profileForm.reset({
      firstName: this.profile.firstName,
      lastName: this.profile.lastName,
      email: this.profile.uid,
      phoneNumber: this.profile.phoneNumber,
      phoneType: this.profile.phoneType,
      password: '************',
      optedIn: this.profile.optedIn
    });
  }

  save() {
    if (this.profileForm.valid) {
      const model = this.profileForm.value;
      const profileChange: ProfileChange = {
        firstName: model.firstName,
        lastName: model.lastName,
        phoneNumber: model.phoneNumber
      };
      this.submitted.emit(profileChange);
    }
  }

  success() {
    // Using timeout because of issue here:
    // https://github.com/ng-bootstrap/ng-bootstrap/issues/1775
    setTimeout(() => {
      this.modalService.open(this.successModal).result.then(
        () => {
          this.profileForm.markAsPristine();
        },
        () => {
          this.profileForm.markAsPristine();
        }
      );
    });
  }

  // Email

  openProfileEmail() {
    this.profileEmailChangeError = false;
    this.editEmailModalRef = this.modalService.open(this.editEmailModal);
  }

  onSubmitProfileEmail(emailChange: EmailChange) {
    this.profileEmailSubmitted.emit(emailChange);
  }

  // Password

  openProfilePassword() {
    this.editPasswordModalRef = this.modalService.open(this.editPasswordModal);
  }

  onPasswordUpdatedSuccessfully() {
    const path = this.router.url;
    const editPassword = /edit-password/gi;

    // If it doesn't match then we are not in mobile mode and we can show this modal.
    if(!path.match(editPassword)) {
      this.modalService.open(this.passwordChangeConfirmationModal).result.then(
        () => {
          this.modalService.dismissAll();
        },
        () => {
          this.modalService.dismissAll();
        }
      );
    }

  }

  closeUpdateModal() {
    this.modalService.dismissAll();
  }

  onSubmitProfilePassword(passwordChange: PasswordChange) {
    this.profilePasswordSubmitted.emit(passwordChange);   
  }

  // Address
  openProfileAddress(address: Address = null) {
    this.profileAddressOpened.emit(address);
  }

  onSubmitProfileAddress(address: Address) {
    this.profileAddressSubmitted.emit(address);
  }

  onSubmitSuggestedAddress(address: Address) {
    this.suggestedAddressSubmitted.emit(address);
  }

  confirmDeleteAddress(address: Address) {
    if(this.profileAddresses.addresses.length > 1){
      this.profileAddress = address;

      this.modalService.open(this.confirmDeleteAddressModal).result.then(
        () => {
          this.deleteProfileAddress(address);
        },
        () => {
        }
      );
    }else{
      this.modalService.open(this.alertDeleteAddressModal).result.then(
        () => {
        },
        () => {
        }
      );
    }
  }

  deleteProfileAddress(address: Address) {
    this.profileAddressRemoved.emit(address);
  }

  makePrimaryProfileAddress(address: Address) {
    this.profileAddressSetPrimary.emit(address);
  }

  // Payment

  openProfilePayment(payment: Payment = null) {
    this.profilePaymentOpened.emit(payment);
  }

  onSubmitProfilePayment(event: PaymentBag) {
    if (this.editPaymentModalRef) {
      this.editPaymentModalRef.close();
    }
    this.profilePaymentSubmitted.emit(event);
  }

  confirmDeletePayment(payment: Payment) {
    if(this.profilePayments.payments.length > 1){
    this.profilePayment = payment;
    this.modalService.open(this.confirmDeletePaymentModal).result.then(
      () => {
        this.deleteProfilePayment(payment);
      },
      () => {
      }
    );
    }else{
      this.modalService.open(this.alertDeletePaymentModal).result.then(
        () => {
        },
        () => {
        }
      );
    }
  }

  deleteProfilePayment(payment: Payment) {
    this.profilePaymentRemoved.emit(payment);
  }

  makePrimaryProfilePayment(payment: Payment) {
    return new Promise(resolve => {
      resolve(this.profilePaymentSetPrimary.emit(payment));
    });
  }

  confirmMakePrimaryProfilePayment(payment: Payment) {
      this.profilePayment = payment;
      this.modalService.open(this.confirmMakePrimaryPaymentModal).result.then(
        async () => {
          await this.makePrimaryProfilePayment(payment);
          this.modalService.open(this.successPrimaryProfilePaymentModal)
        },
        () => {
        }
      );
  }

  // Opted In

  onSubmitProfileOptedIn(optedIn: boolean) {
    this.profileOptedInSubmitted.emit(optedIn);
  }


  clearPaymentError() {
    this.profilePaymentChangeError = null;
  }

  /**
   * Automatically insert '-' in phone number as per format
   * @param event Trigger from input key 
   */
  onKey(event: any) {
    let chIbn = event.target.value.split('-').join('');
    if (chIbn.length > 0 && event.target.value.length < 10) {
      console.log(chIbn);
      chIbn = chIbn.match(new RegExp('.{1,3}', 'g')).join('-');
      this.profileForm.controls['phoneNumber'].setValue(chIbn,{onlySelf: true});
    }
  }


  //Accordion
  toggle(viewItem) {
    viewItem.show = true;
    viewItem.hide = !viewItem.hide;
    // CHANGE THE NAME OF THE BUTTON.
    if(viewItem.hide)
      viewItem.buttonName = "View Less";
    else
      viewItem.buttonName = "View More";
  }

}
