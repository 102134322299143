import { DeliveryFrequencies } from './../../../shared/constants/payment';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AddingCart, AutoShipTemplate, NutritionPlan, ProductDetail, SearchedProduct, Pet } from '../../../api';
import { quantities } from '../../../shared/constants/shop';
import { ProductModalComponent } from '../product-modal/product-modal.component';
import { ShopUtil } from '../../../shared/utils/shopUtil';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit, OnChanges {


  keyBenefitsExist: boolean = false;

  subsConsent:boolean = false;

  shopForm: UntypedFormGroup;

  quantities = quantities;

  DeliveryFrequencies = DeliveryFrequencies;

  selectedPanels = {};

  @Input() clinicId: string | null;

  @Input() changeProduct: boolean | false;

  @Input() isMobile: boolean | false;

  @Input() pending: boolean | false;

  @Input() product: ProductDetail | null;

  @Input() nutritionPlan: NutritionPlan | null;

  @Output() submitted = new EventEmitter<AddingCart>();

  @Input() subscription: AutoShipTemplate | null;

  @Input() subscriptionChangePending: boolean | false;

  @Input() pet: Pet | null;

  @Output() subscriptionProductChanged = new EventEmitter<string>();

  @Output() navigatedBack = new EventEmitter();

  @Output() getProductDetail = new EventEmitter();

  @ViewChild('successModal', { static: true })
  successModal: any;

  @ViewChild('productChangedModal', { static: true })
  productChangedModal: any;
  @ViewChild('consentModal', { static: true })
    consentModal: any;

  totalPrice: number;
  basePrice: number;
  discountedPrice: number;
  totalDiscountedPrice: number;
  totalProductToShow: number = 2; // reference products count to be shown on mobile  
  referenceProducts: any[];

  windowRef: any; 

  constructor(private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private router: Router) {
      this.windowRef = window;
  }

  ngOnInit() {
    this.createForm();
  
  }

  ngOnChanges(changes: SimpleChanges) {
    const pendingChange: SimpleChange = changes['pending'];
    if (this.product) {
      this.doesKeyBenefitsExist();
    }
  
    if (pendingChange && !pendingChange.firstChange) {
      if (pendingChange.currentValue) {
        this.shopForm.disable();
      } else {
        this.shopForm.enable();
        this.success();
      }
    }
    const subscriptionChangePendingChange: SimpleChange = changes['subscriptionChangePending'];
    if (subscriptionChangePendingChange && !subscriptionChangePendingChange.firstChange) {
      if (subscriptionChangePendingChange.currentValue) {
        this.shopForm.disable();
      } else {
        this.shopForm.enable();
        this.productChangedSuccess();
      }
    }
    const productChange: SimpleChange = changes['product'];
    if (productChange && !productChange.firstChange) {
      this.setShopFormValues(this.shopForm);
  
      // formulating total price , (product baseprice * quantity)
      if (this.product.baseOptions && this.product.baseOptions[0] && this.product.baseOptions[0].selected && this.product.baseOptions[0].selected.priceData) {
        this.basePrice = this.product.baseOptions[0].selected.priceData.value;
      } else {
        this.basePrice = 0;
      }
  
      let currentQuantity = this.shopForm.get('quantity').value || 1;
      this.totalPrice = this.basePrice * currentQuantity;
  
      // formulating discounted price
      this.discountedPrice = this.product.discountedPrice || 0;
      this.totalDiscountedPrice = this.discountedPrice * currentQuantity;
  
      // Use all reference products, do not slice
      this.referenceProducts = this.product.productReferences;
  
      let dataLayer = this.windowRef['dataLayer'] || [];
  
      dataLayer.push({
        event: "view_item",
        ecommerce: {
          currency: this.product.baseOptions?.[0]?.selected?.priceData?.currencyIso || 'USD',
          value: this.discountedPrice.toFixed(2),
          items: [
          {
            item_id: this.product.baseOptions?.[0]?.selected?.code || '',
            item_name: this.product.name,
            discount: (this.product.baseOptions?.[0]?.selected?.priceData?.value - this.discountedPrice).toFixed(2) || '0.00',
            item_variant: this.product.baseOptions?.[0]?.variantType || '', 
            price: this.product.baseOptions?.[0]?.selected?.priceData?.value.toFixed(2) || '0.00',
            quantity: 1
          }
          ]
        }
      });
  
      // Tracking reference products list view event
      this.trackReferenceProductsListView();
    }
  }

  trackReferenceProductsListView() {
    if (this.referenceProducts?.length > 0) {
      let dataLayer = this.windowRef['dataLayer'] || [];

      let products = this.referenceProducts.map((reference, index) => {
        const product = reference.target;
        const price = product?.baseOptions?.[0]?.selected?.priceData?.value ? product.baseOptions[0].selected.priceData.value.toFixed(2) : '0.00';
        const discount = product ? (product.baseOptions?.[0]?.selected?.priceData?.value > product.discountedPrice ? (product.baseOptions?.[0]?.selected?.priceData?.value - product.discountedPrice).toFixed(2) : '0.00') : '0.00';

        return {
          item_id: product?.code || '',
          item_name: product?.name || '',
          index: index,
          discount: discount,
          quantity: 1,
          price: price
        };
      });

      dataLayer.push({
        event: "view_item_list",
        ecommerce: {
          items: products
        }
      });
    }
  }
  selectItem(product: any) {
    let dataLayer = this.windowRef['dataLayer'] || [];
    const productCode = product.code; 
    const msrpPrice = product.baseOptions[0].selected.priceData.value; 
    const discountedPrice = this.discountedPrice; 
    const index = 0;
    const discount = product ? (product.baseOptions?.[0]?.selected?.priceData?.value > product.discountedPrice ? (product.baseOptions?.[0]?.selected?.priceData?.value - product.discountedPrice).toFixed(2) : '0.00') : '0.00'; 
  
    dataLayer.push({
      event: "select_item",
      ecommerce: {
        items: [
          {
            item_id: productCode,
            item_name: product.name,
            discount: discount,
            quantity: 1,
            price: msrpPrice.toFixed(2),
            index: index
          }
        ]
      }
    });
  }

  doesKeyBenefitsExist() {

    const mainUSPClaims  = this.product.mainUSPClaims;

    const parser = new DOMParser();

    const domTree = parser.parseFromString(mainUSPClaims, "text/html");

    let currentNode = domTree.children[0]; // Tree structure

    let stack = [currentNode];

    // lets find the table first using a breadth-first search of the tree data structure
    while(stack.length) {

      currentNode = stack.pop();

      if(currentNode.nodeName == "TABLE") {
        // We have found the currentNode and the currentNode is equal to what we are looking for, so break.
        break;
      }

      for(let i = 0; i < currentNode.children.length; i++ ) {
        stack.push(currentNode.children.item(i));
      }
      

    } 


  while(true) {
      // after table is found
      if(currentNode.nodeName == "TD") {
        // If there is content
        if(currentNode.innerHTML.length > 0) {
          this.keyBenefitsExist = true;
        }
        else this.keyBenefitsExist = false;

        break;
      }
      // Failsafe to ensure that the while loop will always break
      else if(currentNode.children.length === 0 ) break;

      // This goes as far to the left as possible. 
      // I do this because sometimes the headers don't have data, and this is okay. 
      // We don't want to accidentally find a header and then not display the table because of this header.
      currentNode = currentNode.children[currentNode.children.length - 1];
    }
  }

  setShopFormValues(shopForm) {
    let currentQuantity = this.shopForm.get('quantity').value;
    let formKeys;
    if(this.product.subscriptionUnit)
    {
      formKeys = [{ key: 'quantity', value: currentQuantity },
      { key: 'size', value: this.product.baseOptions[0].selected.code },
      { key: 'frequency', value: this.product.subscriptionUnit.deliveryFrequency },
      { key: 'duration', value: this.product.subscriptionUnit.duration }
      ];
    }else             //for change product
    {
      formKeys = [{ key: 'quantity', value: currentQuantity },
      { key: 'size', value: this.product.baseOptions[0].selected.code }
      ];
    }

    formKeys.forEach(form => {
      shopForm.controls[form.key].setValue(form.value);
    })
  }

  createForm() {
    this.shopForm = this.fb.group({
      quantity: [1, Validators.required],
      size: ['', Validators.required],
      frequency: [''],
      duration: ['']
    });
  }

  addToCart() {
    if (this.shopForm.valid) {
      const model = this.shopForm.value;
      const addingCart: AddingCart = {
        quantity: '' + model.quantity,
        product: {
          code: this.product.code
        },
        subscriptionUnit: { deliveryFrequency: model.frequency, duration: model.duration },
        subsConsent: this.subsConsent
      };

      let dataLayer = this.windowRef['dataLayer'] || [];


      const ga4Tag = {
        event: 'add_to_cart',
        ecommerce: {
          currency: this.product.baseOptions[0].selected.priceData.currencyIso,
          value: parseFloat((this.discountedPrice * model.quantity).toFixed(2)),
          items
            : [{
              item_id: this.product.code,                
              item_name: this.product.name, 
              item_variant: this.product.baseOptions[0].selected.size,
              discount: parseFloat((this.totalPrice - this.totalDiscountedPrice).toFixed(2)),
              price: parseFloat(this.basePrice.toFixed(2)),
              quantity: model.quantity
            }],
        }
      };
      dataLayer.push(ga4Tag);

      this.submitted.emit(addingCart);
    }
  }
  navigateToSubscriptions(event: Event) {
    event.preventDefault();  
    this.modalService.dismissAll(); 
    this.router.navigate(['/subscriptions']); 
  }
  selectProduct() {
    if (this.shopForm.valid) {
      this.subscriptionProductChanged.emit(this.product.code);
    }
  }

  success() {
    // Using timeout because of issue here:
    // https://github.com/ng-bootstrap/ng-bootstrap/issues/1775
    setTimeout(() => {
      this.modalService.open(this.successModal).result.then(
        () => {
          this.router.navigate(['/cart']);
        },
        () => {
        }
      );
    });
  }

  productChangedSuccess() {
    // Using timeout because of issue here:
    // https://github.com/ng-bootstrap/ng-bootstrap/issues/1775
    setTimeout(() => {
      this.modalService.open(this.productChangedModal).result.then(
        () => {
          //this.router.navigate(['/subscriptions', this.subscription.subscriptionId]);
          this.router.navigate(['/subscriptions']);
        },
        () => {
          //this.router.navigate(['/subscriptions', this.subscription.subscriptionId]);
          this.router.navigate(['/subscriptions']);
        }
      );
    });
  }

  fetchProductDetailOnSizeChange() {
    const productCode = this.shopForm.get('size').value;
    const petCode = this.pet.petCode;
    this.getProductDetail.emit({ clinicId: this.clinicId, productCode: productCode, petCode: petCode });
    //this.router.navigate(['/np-product', this.clinicId, productCode]);
  }

  onQuantityRemoved() {
    let currentQuantity = this.shopForm.get('quantity').value;
    if (currentQuantity > 1) {
      let updatedQuantity = currentQuantity - 1;
      this.shopForm.controls['quantity'].setValue(updatedQuantity);
      this.calculateTotalPrice(updatedQuantity);
    }
  }

  onQuantityAdded() {
    let currentQuantity = this.shopForm.get('quantity').value;
    if (currentQuantity <= 9) {
      let updatedQuantity = currentQuantity + 1;
      this.shopForm.controls['quantity'].setValue(updatedQuantity);
      this.calculateTotalPrice(updatedQuantity);
    }
  }
  onAddToCartClicked() {
    this.subsConsent = false;
    if (this.shopForm.get('frequency').value !== this.DeliveryFrequencies.OneTime) {
        this.modalService.open(this.consentModal);
    } else {
        this.addToCart();
    }
}

onDontWantToSubscribe(modal: any) {
    // Closes the modal
    modal.close('noSubscription');
    // For a one-time order, set the frequency to OneTime
    this.shopForm.get('frequency').setValue(this.DeliveryFrequencies.OneTime);
    // Add to cart as a one-time order
    this.addToCart();
}

onAcknowledge(modal: any) {
    // Closes the modal
    this.subsConsent = true;
    modal.close('acknowledge');
    // Calls the addToCart function
    this.addToCart();
}
  // price calculation based on quantity changed
  calculateTotalPrice(quantity) {
    this.totalPrice = this.basePrice * quantity;
    this.totalDiscountedPrice = this.discountedPrice * quantity;
  }

  navigateToProductListing() {
    this.router.navigate(['pets/shop/', this.nutritionPlan.pet.petCode]);
  }

  loadMoreProducts() {
    this.totalProductToShow += 4;
    this.referenceProducts = this.product.productReferences;
    this.referenceProducts = this.referenceProducts.slice(0, this.totalProductToShow);
  }

  /**
   * Opens a ProductModal to display the zoom of the product being viewed
   */
  open() {
    const modalRef = this.modalService.open(ProductModalComponent, { size: 'lg' })
    modalRef.componentInstance.product = this.product;
  }

  goBack() {
    this.shopForm.reset();
    this.navigatedBack.emit();
  }
}
