<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout custom-font terms-not-logged-in" *ngIf="isLoggedIn">
    <div class="header-banner">
      <div class="d-flex align-items-center flex-shrink-0 p-4">
        <a href="javascript:void(0)" (click)="goBack()" *ngIf="isTermsAccepted">
          <i class="fas fa-angle-left fa-2x"></i>
        </a>
        <h5 class="flex-grow-1 mb-0" [ngClass]="{'ms-4':isTermsAccepted}">
          Hill's to Home Terms of Service & Privacy Policy
        </h5>
      </div>
      <div class="flex-grow-1 p-4 app-scrollable">
        <div>
          <b>Last Updated {{termsAndConditions?.revisionDate | date: 'longDate'}}</b>
          <div class="recent-update-msg mb-2 mt-2" *ngIf="isLoggedIn && !isTermsAccepted &&isTermsAcceptedEarlier">We recently updated our Terms & Conditions and Privacy Policy, please review them and click accept to access your account.</div>
          <div class="tnc-content" [innerHTML]="termsAndConditions?.termsAndConditions"></div>
        </div>
      </div>
      <div class="d-flex align-items-center flex-shrink-0 p-2" *ngIf="!isTermsAccepted">
        <div class="w-50 ps-1 mt-4 me-2">
          <!-- Place holder div-->
          <br/><br/><br/>
        </div>
        <div class="w-50 ps-1 mt-4 ms-auto me-2">
          <span class="consent">By proceeding, I agree to Hill's Pet
            Nutrition, Inc. Terms of Service, and
            <a href="https://www.colgatepalmolive.com/en-us/legal-privacy-policy" target="_blank">Privacy policy</a></span><br/>
        </div>     
      </div>
      <div class="d-flex align-items-center flex-shrink-0 p-2" *ngIf="!isTermsAccepted">
        <button type="button" class="btn btn-secondary ps-5 pe-5 mt-2 ms-auto me-2  btn-lg" (click)="cancel()">
              Cancel
        </button>
        <button type="button" class="btn btn-primary ps-5 pe-5 mt-2 ms-2 me-auto btn-lg" (click)="accept()">
          Accept
        </button>        
      </div>
    </div>
  </div>
  <div class="app-mobile-layout custom-font terms-not-logged-in" *ngIf="!isLoggedIn">
    <div class="header-banner">
      <div class="container d-flex align-items-start pt-4">
        <div class="home-logo">
          <img src="assets/images/060118_HillsToHomeLogo.png" routerLink="/login">
        </div>
        <button class="visit-btn mt-3" (click)="openUrl()">
          <span class="w700">VISIT HILLSPET.COM</span>
          <i class="fa fa-external-link-square-alt ps-2"></i>
        </button>
      </div>
      <div class="d-flex align-items-center flex-shrink-0 p-4">
        <a href="javascript:void(0)" (click)="goBack()">
          <i class="fas fa-angle-left fa-2x"></i>
        </a>
        <h5 class="flex-grow-1 ms-4 mb-0">
          Hill's to Home Terms of Service & Privacy Policy
        </h5>
      </div>
      <div class="flex-grow-1 p-4 app-scrollable">
        <b>Last Updated {{termsAndConditions?.revisionDate | date: 'longDate'}}</b>
        <div class="tnc-content" [innerHTML]="termsAndConditions?.termsAndConditions"></div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #fullLayout>
  <div class="app-full-layout custom-font">
    <div class="header-banner">
    <div class="container d-flex align-items-start pt-4" *ngIf="!isLoggedIn">
      <div class="home-logo">
        <img src="assets/images/060118_HillsToHomeLogo.png" routerLink="/login">
      </div>
      <button class="visit-btn mt-3 me-2" routerLink="/login">
        <span class="w700">Sign In</span>
        <i class="fas fa-user ps-2"></i>
      </button>
      <button class="visit-btn mt-3" (click)="openUrl()">
        <span class="w700">VISIT HILLSPET.COM</span>
        <i class="fa fa-external-link-square-alt ps-2"></i>
      </button>
    </div>
    <div class="container pt-5 pb-5">
      <h2 >Hill's to Home Terms of Service & Privacy Policy</h2>
      <div class="d-flex flex-column">
        <b>Last Updated {{termsAndConditions?.revisionDate | date: 'longDate'}}</b>
        <div class="recent-update-msg mb-2 mt-2" *ngIf="isLoggedIn && !isTermsAccepted && isTermsAcceptedEarlier">We recently updated our Terms & Conditions and Privacy Policy, please review them and click accept to access your account.</div>
        <div class="scroll-content" [innerHTML]="termsAndConditions?.termsAndConditions"></div>
      </div>
      <div class="d-flex flex-column" >
        <div class="d-inline-flex align-content-end" *ngIf="!isTermsAccepted">  
          <div class="me-2 mt-4 mb-4">
            <br/>
            <br/>
            <br/>
             <button type="button" class="btn btn-secondary ps-5 pe-5 btn-lg me-2 mt-4 mb-4" (click)="cancel()">
            Cancel
          </button></div>
          <div class="pe-5 btn-lg me-4 mt-4 mb-4"><span class="consent">By proceeding, I agree to Hill's Pet<br/>
            Nutrition, Inc. Terms of Service, and<br/>
            <a href="https://www.colgatepalmolive.com/en-us/legal-privacy-policy" target="_blank">Privacy policy</a></span><br/>
            <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg me-4 mt-4 mb-4" (click)="accept()">
              Accept
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</ng-template>

<ng-template #terms>

</ng-template>
