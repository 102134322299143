/**
 * Pet Companion Server
 * This is a sample server Pet Companion server. You can find out more about Swagger at [http://swagger.io](http://swagger.io) or on [irc.freenode.net, #swagger](http://swagger.io/irc/). For this sample, you can use the api key `special-key` to test the authorization filters.
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Brand } from './brand';
import { ProductLine } from './productLine';
import { Pet } from './pet';


export interface SubscriptionUnit {
    deliveryFrequency?: string;
    duration?: string;
    endDate?: string;
    availableDeliveryFrequencies?: Array<string>;
    availableDuration?: Array<string>;
    brands?: Array<Brand>;
    nutritionPlanId?: string;
    productLines?: Array<ProductLine>;
    species?: string;
    isPrescriptionDiet?: boolean;
    pets?: Array<Pet>;              //for Change Product
    
}
