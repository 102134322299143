import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { TermsAndConditions, Profile } from 'src/app/api';
import { Router } from '@angular/router';
import { TermsStatusChanged } from '../../models/termsStatusChanged';
import { DateUtil } from '../../../shared/utils/dateUtil';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  @Input()
  isMobile: boolean | false;

  @Input()
  isLoggedIn: boolean | false;

  @Input()
  isTermsAccepted: boolean | true;

  @Input()
  isTermsAcceptedEarlier: boolean | false;

  @Output()
  accepted = new EventEmitter<TermsStatusChanged>();

  @Output()
  loggedOut = new EventEmitter();

  @Input()
  termsAndConditions: TermsAndConditions | null;

  constructor(private location: Location, private router: Router) {}

  ngOnInit() {}

  accept() {
    const eventData: TermsStatusChanged = {
      accepted: true
    };
    this.accepted.emit(eventData);
  }

  cancel() {
    this.loggedOut.emit();
  }

  goBack() {
    this.location.back();
  }

  openUrl() {
    window.open('https://www.hillspet.com/', '_blank');
  }
}
