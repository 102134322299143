<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout">
    <p class="promotion-txt mt-3 mx-3" *ngIf="profile?.cardExpiringSoon">Our records indicate that your primary payment method will expire within 30 days or has already expired. To avoid order interruption, please add a new primary payment method and delete the payment method that is about to expire or has expired.</p>
    <h5 class="app-title text-center m-4">
      My Account
    </h5>

    <h6 class="m-4">Profile</h6>

    <div class="m-4" *ngIf="profile">
      <a routerLink="/profile/edit-name" class="text-decoration d-flex border p-3 align-items-center">
        <span class="w-25">Name</span>
        <span class="flex-grow-1">{{profile.firstName}} {{profile.lastName}}</span>
        <i class="fas fa-angle-right"></i>
      </a>
      <a routerLink="/profile/edit-email" class="text-decoration d-flex border p-3 mt-2 align-items-center">
        <span class="w-25">Email</span>
        <span class="flex-grow-1">{{profile.uid}}</span>
        <i class="fas fa-angle-right"></i>
      </a>
      <a routerLink="/profile/edit-phone" class="text-decoration d-flex border p-3 mt-2 align-items-center">
        <span class="w-25">Phone</span>
        <span class="flex-grow-1">{{profile.phoneNumber}}</span>
        <i class="fas fa-angle-right"></i>
      </a>
      <a routerLink="/profile/edit-password" class="text-decoration d-flex border p-3 mt-2 align-items-center">
        <span class="w-25">Password</span>
        <span class="flex-grow-1">************</span>
        <i class="fas fa-angle-right"></i>
      </a>
    </div>

    <div class=" d-flex m-4 align-items-end">
      <h6 class="flex-grow-1">Address</h6>
      <a class="btn btn-primary btn-sm" [routerLink]="['/profile/add-address']">
        + Add Address
      </a>
    </div>

    <div class="m-4">
      <p>*All orders and subscriptions are delivered to the Primary Address</p>
    </div>

    <div class="m-4" *ngIf="profileAddresses?.addresses !== undefined">

      <div *ngFor="let address of profileAddresses?.addresses;let i=index">
        <a [routerLink]="['/profile/edit-address', address.id]"
          class="text-decoration d-flex border p-3 align-items-center testing{{i}}" [class.mt-2]="i!==0"
          [class.is-primary]="address.defaultAddress" *ngIf="i > 1 ? addressMore.hide : addressMore.show">
          <div class="d-flex flex-column flex-grow-1">
            <span>
              {{address.line1}}
              {{address.line2}}
            </span>
            <span>
              {{address.town}},
              <!--state info added as requested by business -  -->
              {{address.region.isocode | appRegion}}
              <!--END-->
              {{address.postalCode}}
            </span>
          </div>
          <div>
            {{address.defaultAddress ? '(Primary)' : ''}}
          </div>
          <i class="fas fa-angle-right ms-3"></i>
        </a>
      </div>

      <div class="horizontal-line" *ngIf="profileAddresses!== null && profileAddresses?.addresses.length > 2"></div>
      <a (click)="toggle(addressMore)" class="viewMore" href="javascript: void(0)"
        *ngIf="profileAddresses!== null && profileAddresses?.addresses.length > 2">
        {{addressMore.buttonName}}
      </a>

    </div>

    <div class="d-flex m-4 align-items-end">
      <h6 class="flex-grow-1">Payment</h6>
      <button class="btn btn-primary btn-sm" [routerLink]="['/profile/add-payment']" [disabled]="!defaultPaymentInfo">
        + Add Payment
      </button>
    </div>
    <div class="m-4" *ngIf="!defaultPaymentInfo">
      <p>*Payment information will be added with your first order during checkout</p>
    </div>

    <div class="m-4" *ngIf="profilePayments">
      <div *ngFor="let payment of profilePayments.payments;let i=index">
        <a [routerLink]="['/profile/edit-payment', payment.id]" class="text-decoration d-flex border p-3 align-items-center"
          [class.mt-2]="i!==0" [class.is-primary]="payment.defaultPaymentInfo"
          *ngIf="i > 1 ? paymentMore.hide : paymentMore.show">
          <div>
            <i [class]="payment.cardType.code | cardTypeToImage: 'app-icon app-credit-card'"></i>
          </div>
          <div class="ps-3 d-flex flex-column flex-grow-1">
            <span>
              <span class="text-capitalize">{{payment.cardType.code}}</span>
              {{' Ending in - ' + payment.cardNumber}}
            </span>
          </div>
          <div>
            {{payment.defaultPaymentInfo ? '(Primary)' : ''}}
          </div>
          <i class="fas fa-angle-right ms-3"></i>
        </a>
      </div>
      <div class="horizontal-line" *ngIf="profilePayments.payments.length > 2"></div>
      <a (click)="toggle(paymentMore)" class="viewMore" href="javascript: void(0)"
        *ngIf="profilePayments.payments.length > 2">
        {{paymentMore.buttonName}}
      </a>
    </div>

    <h6 class="m-4">Preferences</h6>

    <div class="m-4 d-flex" (click)="marketingOptIn = !marketingOptIn; onSubmitProfileOptedIn(marketingOptIn)">
      <i class="far app-icon fa-2x" [class.fa-square]="!marketingOptIn" [class.fa-check-square]="marketingOptIn">
      </i>
      <span class="ps-3">
        I'd like to receive communications from Hill's about products, offers and programs
      </span>
    </div>

  </div>
</ng-template>

<ng-template #fullLayout>
  <div class="app-full-layout">
    <div class="container pt-5 pb-5">
      <form [formGroup]="profileForm" (ngSubmit)="save()">
        <p class="promotion-txt" *ngIf="profile?.cardExpiringSoon">Our records indicate that your primary payment method will expire within 30 days or has already expired. To avoid order interruption, please add a new primary payment method and delete the payment method that is about to expire or has expired.</p>

        <h4 class="fw-normal mt-4">Profile</h4>

        <div class="d-flex mt-4">
          <div class="w-40 pe-4 right-fg">
            <div class="form-group pe-4">
              <label for="firstName" class="small ">First</label>
              <input id="firstName" name="firstName" type="text" class="form-control" placeholder="First Name"
                formControlName="firstName">
            </div>
            <div class="form-group position-relative">
              <label for="email" class="small">Email</label>
              <a href="javascript:void(0)" class="text-decoration small float-right pe-3 ps-3 pt-2" (click)="openProfileEmail()">
                Change
              </a>
              <input id="email" name="email" type="email" class="form-control" placeholder="Email"
                formControlName="email">
            </div>
            <div class="form-group ">
              <label for="password" class="small">Password</label>
              <a href="javascript:void(0)" class="text-decoration small float-right pe-3 ps-3 pt-2" (click)="openProfilePassword()">
                Change
              </a>
              <input id="password" name="password" type="password" class="form-control" placeholder="Password"
                formControlName="password" autocomplete="password">
              </div>
          </div>
          <div class="w-40 ps-4 right-fg">
            <div class="form-group">
              <label for="lastName" class="small">Last</label>
              <input id="lastName" name="lastName" type="text" class="form-control" placeholder="Last name"
                formControlName="lastName">
            </div>
            <div class="form-group">
              <label for="phone" class="small">Phone Number</label>
              <div class="d-flex">
                <input id="phone" name="phone" type="tel" class="form-control" placeholder="Phone Number"
                  formControlName="phoneNumber" (keyup)="onKey($event)">
              </div>
            </div>
            <ul *ngIf="profileForm.controls['phoneNumber'].dirty &&
               profileForm.controls['phoneNumber'].errors &&
               profileForm.controls['phoneNumber'].errors['pattern']" class="list-unstyled app-invalid-feedback small px-3">
              <li *ngIf="profileForm.controls['phoneNumber'].errors['pattern']">
                Phone Number should be 123-123-1234 format
              </li>
              <i class="app-bottom-arrow"></i>
            </ul>
          </div>
        </div>

        <div class="mt-5 text-right">
          <button type="submit" class="btn btn-primary ps-5 pe-5 btn-lg"
            [disabled]="profileForm.pristine || profileForm.invalid">
            Save
          </button>
        </div>

      </form>

      <hr class="mt-5" />

      <div class="d-flex mt-5 align-items-center">
        <h4 class="fw-normal m-0">Address</h4>
        <a class="ms-addr btn btn-primary btn-sm ms-5 ps-3 pe-3" href="javascript:void(0)" (click)="openProfileAddress()">
          + Add Address
        </a>
      </div>

      <div class="mt-3">
        <p>*All orders and subscriptions are delivered to the Primary Address</p>
      </div>
      
      <div class="mt-4" *ngIf="profileAddresses?.addresses !== undefined">

        <div class="d-flex p-4">
          <b class="text-uppercase w-20">Address</b>
          <b class="text-uppercase w-15">City</b>
          <b class="text-uppercase w-15">State</b>
          <b class="text-uppercase w-15">Zip Code</b>
          <b class="text-uppercase w-15">Primary</b>
          <b class="text-uppercase w-10"></b>
          <!-- <b class="text-uppercase w-10"></b> -->
        </div>

        <div class="d-flex border p-4" *ngFor="let address of profileAddresses?.addresses;let i=index"
          [class.mt-3]="i!==0" [class.is-primary]="address.defaultAddress">
          <div class="w-20">
            {{address.line1}} {{address.line2}}
          </div>
          <div class="w-15">
            {{address.town}}
          </div>
          <div class="w-15">
            {{address.region.isocode | appRegion}}
          </div>
          <div class="w-15">
            {{address.postalCode}}
          </div>
          <div class="w-15" *ngIf="address.defaultAddress">
            Primary
          </div>
          <div class="w-15 custom-btn" (click)="makePrimaryProfileAddress(address)" *ngIf="!address.defaultAddress" title="Make Primary Address">
            Make Primary
          </div>
          <div class="w-10 text-center custom-btn" (click)="confirmDeleteAddress(address)"
            title="Delete Address">
            Delete
          </div>
          <!-- <div class="w-10 text-center custom-btn" (click)="profileAddresses?.addresses.length>1 ? confirmDeleteAddress(address) : null"
              [title]="profileAddresses?.addresses.length>1 ? 'Delete Address' : 'Please add new address before removing'">
            Delete
          </div> -->
          <div class="w-10 text-center custom-btn" (click)="openProfileAddress(address)"
            title="Edit Address">
            Edit
          </div>
        </div>
      </div>

      <hr class="mt-5" />

      <div class="d-flex mt-5 align-items-center">
        <h4 class="fw-normal m-0">Payment</h4>
        <button class="ms-pay btn btn-primary btn-sm ms-5 ps-3 pe-3" href="javascript:void(0)" (click)="openProfilePayment()" [disabled]="!defaultPaymentInfo" >
          + Add Payment
        </button>
      </div>
      <div class="mt-3" *ngIf="!defaultPaymentInfo">
        <p>*Payment information will be added with your first order during checkout</p>
      </div>
      <div *ngIf="profilePaymentChangeError" class="alert alert-warning alert-dismissible" role="alert">
        <button type="button" class="close" aria-label="Close" (click)="clearPaymentError()"><span
            aria-hidden="true">&times;</span></button>
        Unable to add card. Reason - {{profilePaymentChangeError}}
      </div>

      <div class="mt-4" *ngIf="profilePayments">

        <div class="d-flex p-4">
          <b class="text-uppercase w-20">Type</b>
          <b class="text-uppercase w-30">Number</b>
          <b class="text-uppercase w-30">Primary</b>
          <b class="text-uppercase w-10"></b>
          <!-- <b class="text-uppercase w-10"></b> -->
        </div>

        <div class="d-flex border p-4" *ngFor="let payment of profilePayments.payments;let i=index" [class.mt-3]="i!==0"
          [class.is-primary]="payment.defaultPaymentInfo">
          <div class="w-20 d-flex align-items-center">
            <i [class]="payment.cardType.code | cardTypeToImage: 'app-icon app-credit-card fa-2x'"></i>
            <span class="ms-3 text-capitalize">{{payment.cardType.code}}</span>
          </div>
          <div class="w-30">
            {{payment.cardNumber}}
          </div>
          <div class="w-30" *ngIf="payment?.defaultPaymentInfo">
            Primary
          </div>
          <!-- Primary and Make Primary showing together -   -->
          <div class="w-30 custom-btn" (click)="confirmMakePrimaryProfilePayment(payment)" *ngIf="!payment?.defaultPaymentInfo">
            Make Primary
          </div>
          <!-- End -->
          <div class="w-10 text-center custom-btn" (click)="confirmDeletePayment(payment)">
            Delete
          </div>
        </div>
      </div>

      <hr class="mt-5" />

      <h4 class="fw-normal mt-5">Preferences</h4>

      <div class="m-4 d-flex" (click)="marketingOptIn = !marketingOptIn; onSubmitProfileOptedIn(marketingOptIn)">
        <i class="far app-icon fa-2x" [class.fa-square]="!marketingOptIn" [class.fa-check-square]="marketingOptIn">
        </i>
        <span class="ps-3">
          I'd like to receive communications from Hill's about products, offers and programs
        </span>
      </div>

    </div>
  </div>
</ng-template>

<ng-template #successModal let-closeSuccess="close" let-dismissSuccess="dismiss">
  <div class="modal-body text-center">
    <i class="mt-4 far fa-check-circle fa-6x txt-success"></i>
    <p class="m-4 h4 text-center">
      You have successfully changed your profile
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg ms-2" (click)="closeSuccess()">
      OK
    </button>
  </div>
</ng-template>

<ng-template #editEmailModal let-closeTerms="close" let-dismissTerms="dismiss">
  <div class="modal-header">
    <div class="flex-grow-1 text-center">
      <h4 class="modal-title">Edit Email</h4>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="dismissTerms()">
      <i class="fa fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <app-edit-email #editEmail [isMobile]="isMobile" [profile]="profile" [error]="profileEmailChangeError"
      (submitted)="onSubmitProfileEmail($event)">
    </app-edit-email>
  </div>
  <div class="modal-footer">

    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg ms-2 w-35"
      [disabled]="editEmail.profileForm.pristine || editEmail.profileForm.invalid || editEmail.profileForm.disabled"
      (click)="editEmail.save();" ngbAutofocus>
      Save
    </button>
    <button type="button" class="btn btn-secondary ps-5 pe-5 btn-lg w-35" (click)="dismissTerms()">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #editPasswordModal let-closeTerms="close" let-dismissTerms="dismiss">
  <div class="modal-header">
    <div class="flex-grow-1 text-center">
      <h4 class="modal-title">Edit Password</h4>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="dismissTerms()">
      <i class="fa fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <app-edit-password #editPassword [isMobile]="isMobile" [profile]="profile" [error]="profilePasswordChangeError"
      (submitted)="onSubmitProfilePassword($event)">
    </app-edit-password>
  </div>
  <div class="modal-footer">

    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg ms-2"
      [disabled]="editPassword.profileForm.pristine || editPassword.profileForm.invalid || editPassword.profileForm.disabled"
      (click)="editPassword.save();" ngbAutofocus>
      Save
    </button>
    <button type="button" class="btn btn-secondary ps-5 pe-5 btn-lg" (click)="dismissTerms()">
      Cancel
    </button>
  </div>
</ng-template>


<ng-template #passwordChangeConfirmationModal let-closeUpdate="close" let-dismissUpdate="dismiss">
  <div class="modal-body text-center">
    <i class="mt-4 far fa-check-circle fa-6x txt-success"></i>
    <p class="m-4 h4 text-center">
      You have successfully updated your password
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg ms-2" (click)="closeUpdateModal()">
      Ok
    </button>
  </div>
</ng-template>


<ng-template #editAddressModal let-closeTerms="close" let-dismissTerms="dismiss">
  <div class="modal-header">
    <div class="flex-grow-1 text-center">
      <h4 class="modal-title">{{profileAddress?.id ? 'Edit' : 'Add'}} Address</h4>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="dismissTerms()">
      <i class="fa fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <app-edit-address #editAddress [isCart]="false" [isMobile]="isMobile" [address]="profileAddress"
      [profileAddressChangeError]="profileAddressChangeError" [profileCorrectedAddress]="profileCorrectedAddress"
      (submitted)="onSubmitProfileAddress($event)" (submitAddress)="onSubmitSuggestedAddress($event)">
    </app-edit-address>
  </div>
  <div class="modal-footer">

    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg ms-2 w-35"
      [disabled]="editAddress.profileForm.pristine || editAddress.profileForm.invalid || editAddress.profileForm.disabled"
      (click)="editAddress.save();" ngbAutofocus>
      Save
    </button>
    <button type="button" class="btn btn-secondary ps-5 pe-5 btn-lg w-35" (click)="dismissTerms()">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #confirmDeleteAddressModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-body">
    <!-- 'X' sign - close confirm modal button, working -   -->
    <button aria-label="Close" class="close" type="button" (click)="dismissConfirm()">
      <i class="fa fa-times"></i>
    </button>
    <!-- END -->
    <p class="m-4 h4 text-center">
      Are you sure you want to delete address {{profileAddress.line1}} {{profileAddress.line2}}?
    </p>
  </div>
  <div class="modal-footer">

    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg ms-2" (click)="closeConfirm()" ngbAutofocus>
      I'm Sure
    </button>
    <button type="button" class="btn btn-secondary ps-5 pe-5 btn-lg" (click)="dismissConfirm()">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #alertDeleteAddressModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-header">
    <div class="flex-grow-1">
      <h4 class="modal-title text-center">Delete Address</h4>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="dismissConfirm()">
      <i class="fa fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <p class="m-4 h5 text-center">
      Please add a new address before removing this address.
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg" (click)="dismissConfirm()" ngbAutofocus>
      Ok
    </button>
  </div>
</ng-template>

<ng-template #editPaymentModal let-closeTerms="close" let-dismissTerms="dismiss">
  <div class="modal-header">
    <div class="flex-grow-1 text-center">
      <h4 class="modal-title">{{profilePayment?.id ? 'Edit'  : 'Add'}} Payment</h4>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="dismissTerms()">
      <i class="fa fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <app-edit-payment #editPayment [isCart]="false" [isMobile]="isMobile" [changeError]="profilePaymentChangeError"
      [payment]="profilePayment" (submitted)="onSubmitProfilePayment($event)">
    </app-edit-payment>
  </div>
  <div class="modal-footer">

    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg ms-2"
      (click)="editPayment.save(!profilePayment?.id);" ngbAutofocus>
      Save
    </button>
    <button type="button" class="btn btn-secondary ps-5 pe-5 btn-lg" (click)="dismissTerms()">
      Cancel
    </button>
  </div>
</ng-template>


<ng-template #confirmDeletePaymentModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-body">
    <!-- 'X' sign - close confirm modal button, working -   -->
    <button aria-label="Close" class="close" type="button" (click)="dismissConfirm()">
      <i class="fa fa-times"></i>
    </button>
    <!-- END -->
    <p class="m-4 h4 text-center">
      Are you sure you want to delete credit card {{profilePayment.cardNumber}}?
    </p>
  </div>
  <div class="modal-footer">

    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg ms-2" (click)="closeConfirm()" ngbAutofocus>
      I'm Sure
    </button>
    <button type="button" class="btn btn-secondary ps-5 pe-5 btn-lg" (click)="dismissConfirm()">
      Cancel
    </button>
  </div>
</ng-template>
<ng-template #alertDeletePaymentModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-header">
    <div class="flex-grow-1">
      <h4 class="modal-title text-center">Delete Payment Method</h4>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="dismissConfirm()">
      <i class="fa fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <p class="m-4 h5 text-center">
      Please add a new payment method before removing this one.
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg" (click)="dismissConfirm()" ngbAutofocus>
      Ok
    </button>
  </div>
</ng-template>

<ng-template #confirmMakePrimaryPaymentModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-body">
    <button (click)="dismissConfirm()" aria-label="Close" class="close" type="button">
      <i class="fa fa-times"></i>
    </button>
    <p class="m-4 h4 text-center">
      Are you sure you would like to make the {{profilePayment.cardType.code.toUpperCase()}} credit card ending in {{profilePayment.cardNumber}} the payment selection for all future orders on the account?
    </p>
  </div>
  <div class="modal-footer">
    <button (click)="closeConfirm()" class="btn btn-primary ps-5 pe-5 btn-lg ms-2" type="button" ngbAutofocus>
      I'm Sure
    </button>
    <button (click)="dismissConfirm()" class="btn btn-secondary ps-5 pe-5 btn-lg" type="button">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #successPrimaryProfilePaymentModal let-closeSuccess="close" let-dismissSuccess="dismiss">
  <div class="modal-body text-center">
    <i class="mt-4 far fa-check-circle fa-6x txt-success"></i>
    <p class="m-4 h4 text-center">
      Your {{profilePayment.cardType.code.toUpperCase()}} credit card ending in {{profilePayment.cardNumber}} is now updated to be the payment selection for all future orders on the account.
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg ms-2" (click)="closeSuccess()">
      OK
    </button>
  </div>
</ng-template>

