<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout">
    <h5 class="app-title text-center m-4">
      My Subscriptions
    </h5>
    <div class="d-flex align-items-center m-4">
      <div class="d-flex align-items-center">
        <h6 class=" mb-0">#{{subscription?.subscriptionId}}</h6>
      </div>
    </div>
    <div class="pe-3 ps-3 pt-3 pb-5 ms-3 me-3 border-bottom" *ngIf="subscription">
      <div class="d-flex">
        <b class="w-40">Pet</b>
        <span class="flex-grow-1">{{subscription.subscriptionItem?.pet}}</span>
      </div>
      <div class="d-flex mt-2">
        <b class="w-40">Status</b>
        <span class="flex-grow-1 app-status">{{subscription.recurringOrderStatus | appOrderStatus}}</span>
      </div>
      <div class="d-flex mt-2">
        <b class="w-40">Estimated Delivery Date<span class="sup-star">&#42;</span></b>
        <span class="flex-grow-1 app-status" *ngIf="subscription.recurringOrderStatus === 'ACTIVE';else ngbPanelDateEmpty">
          {{subscription.nextScheduledDeliveryDate | date: 'MM/dd/yyyy'}}
        </span>
      </div>
      <div class="d-flex mt-2">
        <b class="w-40">Frequency</b>
        <span class="flex-grow-1 app-status">{{subscription.subscriptionItem?.subscriptionUnit?.deliveryFrequency | tolabel : LabelTypes.Frequency}}</span>
      </div>
      <!-- start date info added -   -->
      <div class="d-flex mt-2">
        <b class="w-40">Start Date</b>
        <span class="flex-grow-1 app-status">{{subscription?.createdDate | date: 'MM/dd/yyyy'}}</span>
      </div>
      <!-- END -->
      <div class="d-flex mt-2">
        <b class="w-40">End Date</b>
        <span class="flex-grow-1 app-status">{{subscription.endDate | date: 'MM/dd/yyyy'}}<span *ngIf="subscription.endDate && !subscription?.npEndDate" class="sup-star">&#42;</span></span>
      </div>
    </div>
    <div class="d-flex pe-3 ps-3 pt-5 pb-3 ms-3 me-3" *ngFor="let entry of subscription?.cart?.entries; let i=index"
      [class.border-bottom]="i!==(subscription?.cart?.entries.length - 1)">
      <div class="d-flex flex-column align-items-center">
        <img src="{{entry.product.images | appImg}}" class="app-cart-img" *ngIf="entry.product.images" alt="{{entry.product.name}}" />
        <div class="app-cart-img text-center" *ngIf="!entry.product.images">
          <i class="fas fa-image fa-4x"></i>
        </div>
        <div class="text-black-50 small mt-3">
          SKU {{entry.product.code}}
        </div>
      </div>
      <div class="flex-grow-1 d-flex flex-column ms-2">
        <div>
          {{entry.product.name}}
        </div>
        <div class="mt-2">
          <span class="fw-bold">Pet: </span>
          <span>{{entry.pet}}</span>
        </div>

        <div class="mt-2">
          <span class="fw-bold">Subscription: </span>
          <span>{{entry.subscriptionUnit?.deliveryFrequency | tolabel : LabelTypes.Frequency}}</span>
        </div>

        <div class="mt-2">
          <span class="fw-bold">Qty: </span>
          <span>{{entry.quantity}}</span>
        </div>
        <div class="mt-2" *ngIf="entry.subscriptionUnit && entry.subscriptionUnit.deliveryFrequency !== DeliveryFrequencies.OneTime">
          <span class="fw-bold">Price: </span>
          <span class="text-line-through">{{entry.basePrice.formattedValue}}</span>
          <span class="app-title"> ${{(entry.totalPrice.value / entry.quantity | number: '.2-2')}}</span>
        </div>
        <div class="mt-2" *ngIf="!entry.subscriptionUnit || entry.subscriptionUnit.deliveryFrequency === DeliveryFrequencies.OneTime">
          <span class="fw-bold">Price: </span>
          <span>{{entry.basePrice.formattedValue}}</span>
        </div>
        <div class="mt-2">
          <span class="fw-bold">Subtotal: </span>
          <span>{{subscription?.cart?.totalPrice.formattedValue}}</span>
        </div>
        <div class="mt-2">
          <span class="fw-bold">Estimated Delivery Date: </span>
          <span *ngIf="subscription.recurringOrderStatus === 'ACTIVE';else ngbPanelDateEmpty">{{subscription.nextScheduledDeliveryDate | date: 'MM/dd/yyyy'}}</span>
        </div>
      </div>
    </div>
    <!-- Tooltip text change and action button updates based on subscription status -   -->
    <div class="p-3 mt-2 mb-2" *ngIf="subscription?.recurringOrderStatus === OrderStatuses.Active && subscription?.cart?.entries[0].product.stock.stockLevelStatus !== 'outOfStock' ">
      <a [routerLink]="['/edit-subscription', subscription?.subscriptionId]" class="btn btn-primary btn-lg font-weight-light w-100 mt-3">
        Edit Subscription
      </a>
      <!-- <button type="button" *ngIf="subscription?.recurringOrderStatus !== OrderStatuses.Hold" class="btn btn-secondary app-outline btn-lg w-100 mt-3"
        (click)="confirmPauseSubscription()">
        Put on Hold
      </button> -->
      <button type="button" class="btn btn-secondary app-outline btn-lg w-100 mt-3" (click)="openShipNowPopup()" [disabled]="!subscription?.enableShipNow" [ngClass]="{'disabled': !subscription?.enableShipNow}">
        Ship Now
      </button>
      <div class="d-flex justify-content-start pt-2 shipNow-text" *ngIf="!subscription?.enableShipNow">
       Your order is being processed.  <!-- Estimated Delivery is {{subscription?.nextScheduledDeliveryDate | date: 'MM/dd/yyyy'}}. -->
      </div>
      <button type="button" *ngIf="subscription?.nextSkipDeliveryDate" class="btn btn-secondary app-outline btn-lg w-100 mt-3"
        (click)="openSkipSubscription(subscription)">
        Skip Next Order
      </button>
      <a *ngIf="subscription.cart.entries[0].subscriptionUnit.nutritionPlanId !== undefined"
      [routerLink]="['/np-products-change', subscription?.cart.b2bUnit,subscription?.subscriptionId]"
      class="btn btn-secondary app-outline btn-lg w-100 mt-3">
        Change Product
      </a>
      <button type="button" *ngIf="subscription?.recurringOrderStatus === OrderStatuses.Hold" class="btn btn-secondary app-outline btn-lg w-100 mt-3"
        (click)="showNextSubscriptionDateModal(subscription)">
        Reactivate Subscription
      </button>
      <button type="button" class="btn btn-secondary btn-cancel-subscription app-outline btn-lg w-100 mt-3" (click)="openCancelSubscription()">
        Cancel Subscription
      </button>
    </div>
    <!-- For Subscription and Product out of stock -   -->
    <div class="p-3 mt-2 mb-2" *ngIf="(subscription?.recurringOrderStatus === OrderStatuses.OutOfStock || (subscription?.recurringOrderStatus === OrderStatuses.Active && subscription?.cart?.entries[0].product.stock.stockLevelStatus === 'outOfStock'))">
      <!-- Edit subscription gets disabled if product out of stock or subscription is out of stock -   -->
      <a [routerLink]="['/edit-subscription', subscription?.subscriptionId]" [ngClass]="{'disabled': 'true' }" class="btn btn-primary btn-lg font-weight-light w-100 mt-3">
        Edit Subscription
      </a>
      <!-- End -->
      <div class="d-flex justify-content-start pt-2 shipNow-text">Unfortunately, this food is temporarily unavailable. When we have it back in stock, we'll reactivate your subscription and send you an email to let you know.</div>
      <!-- <a *ngIf="subscription.cart.entries[0].subscriptionUnit.nutritionPlanId !== undefined "
        [routerLink]="['/np-products-change', subscription?.cart.b2bUnit,subscription?.subscriptionId]" class="btn btn-secondary app-outline btn-lg w-100 mt-3">
        Change Product
      </a> -->
      <button type="button" class="btn btn-secondary btn-cancel-subscription app-outline btn-lg w-100 mt-3" (click)="openCancelSubscription()">
        Cancel Subscription
      </button>
    </div>
    <!-- End -->
    <div class="d-flex pe-3 ps-3 pt-3 mb-3 ms-3 me-3 note-orders" *ngIf="subscription?.subscriptionId != null">
      <p>
        <span class="sup-star">&#42;</span>Note: Please visit <a (click)="getOrders()" title="Orders">Orders</a> to view Estimated delivery dates for In progress orders.
        <br/>
        <span class="sup-star">&#42;</span>Note: Legacy subscription that will not renew automatically.
        <br/>
        <span class="sup-star">&#42;</span>*Note: Save up to 35% on all Hill's products.
      </p>
    </div>
  </div>
</ng-template>

<ng-template #ngbPanelDateEmpty>
  <span class="flex-grow-1 app-status">{{' Not Applicable '}}</span>
</ng-template>

<ng-template #fullLayout>
  <div class="app-full-layout">
  </div>
</ng-template>

<ng-template #cancelSubscriptionModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-header confirmcancel">
    <div class="header-container flex-grow-1">
      <h4 class="modal-title">Cancel Subscription</h4>
      <button type="button" class="close" aria-label="Close" (click)="dismissConfirm()">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
  <div class="modal-body cancelsubsModal">
    <p  *ngFor="let entry of subscription?.cart?.entries; let i=index"
    [class.border-bottom]="i!==(subscription?.cart?.entries.length - 1)">
      Are you sure you want to cancel subscription for the product <b>"{{entry?.product.name}}"</b>?
    </p>
    <p  *ngIf="subscription?.nextSkipDeliveryDate && subscription?.recurringOrderStatus === OrderStatuses.Active && subscription?.cart?.entries[0].product.stock.stockLevelStatus !== 'outOfStock'">You can also <b>skip this order</b>.</p>
    <div class="d-flex justify-content-center mt-4">
      <button type="button"  *ngIf="subscription?.nextSkipDeliveryDate && subscription?.recurringOrderStatus === OrderStatuses.Active && subscription?.cart?.entries[0].product.stock.stockLevelStatus !== 'outOfStock'" class="btn btn-outline btn-sm ms-3 me-9" (click)="openSkipSubscription(subscription)">Skip Next Order</button>
      <button type="button" class="btn btn-danger ms-2" (click)="proceedToCancelSubscription()">Cancel Subscription</button>
    </div>
  </div>
</ng-template>
<ng-template #confirmCancelModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-header confirmcancel">
    <div class="header-container">
        <h4 class="modal-title">Subscription Cancellation Reason</h4>
        <button type="button" class="close" aria-label="Close" (click)="checkCancelSubscription(subscription)">
            <i class="fa fa-times"></i>
        </button>
    </div>
</div>
  <div class="modal-body confirmcancelbody">
    <!-- <p class="m-4 h2 fw-bold" *ngFor="let entry of subscriptionDetail?.cart?.entries; let i=index">
      Are you sure you want to cancel subscription for the product "{{entry?.product.name}}" ?
    </p> -->

    <div>
      <p class="fw-normal">
        Select Reason for Cancellation:
      </p>

      <form [formGroup]="subscriptionCancelForm" autocomplete="off" class="ms-4">
        <div class="custom-control custom-radio mt-3">
          <input type="radio" id="toomuchfood" (change)="changeRadioStatus()" class="custom-control-input"
            name="subscriptionCancelReasonRadio" formControlName="subscriptionCancelReasonRadio"
            value="I have too much food">
          <label class="custom-control-label" for="toomuchfood">
            I have too much food
          </label>
        </div>

        <div class="custom-control custom-radio mt-3">
          <input type="radio" (change)="changeRadioStatus()" id="soon" class="custom-control-input"
            name="subscriptionCancelReasonRadio" formControlName="subscriptionCancelReasonRadio"
            value="I need this food sooner">
          <label class="custom-control-label" for="soon">
            I need this food sooner
          </label>
        </div>

        <div class="custom-control custom-radio mt-3">
          <input type="radio" (change)="changeRadioStatus()" id="service" class="custom-control-input"
            name="subscriptionCancelReasonRadio" formControlName="subscriptionCancelReasonRadio"
            value="Service issue with earlier delivery">
          <label class="custom-control-label" for="service">
            Service issue with earlier delivery
          </label>
        </div>

        <div class="custom-control custom-radio mt-3">
          <input type="radio" (change)="changeRadioStatus()" id="taste" class="custom-control-input"
            name="subscriptionCancelReasonRadio" formControlName="subscriptionCancelReasonRadio"
            value="My pet didn't like the taste">
          <label class="custom-control-label" for="taste">
            My pet didn't like the taste
          </label>
        </div>

        <div class="custom-control custom-radio mt-3">
          <input type="radio" (change)="changeRadioStatus()" id="expensive" class="custom-control-input"
            name="subscriptionCancelReasonRadio" formControlName="subscriptionCancelReasonRadio"
            value="It's too expensive">
          <label class="custom-control-label" for="expensive">
            It's too expensive
          </label>
        </div>

        <div class="custom-control custom-radio mt-3">
          <input type="radio" (change)="changeRadioStatus()" id="diffFood" class="custom-control-input"
            name="subscriptionCancelReasonRadio" formControlName="subscriptionCancelReasonRadio"
            value="I'm changing to a different Hill's food">
          <label class="custom-control-label" for="diffFood">
            I'm changing to a different Hill's food
          </label>
        </div>

        <div class="custom-control custom-radio mt-3">
          <input type="radio" (change)="changeRadioStatus()" id="diffBrand" class="custom-control-input"
            name="subscriptionCancelReasonRadio" formControlName="subscriptionCancelReasonRadio"
            value="I'm changing to a different brand of food">
          <label class="custom-control-label" for="diffBrand">
            I'm changing to a different brand of food
          </label>
        </div>

        <div class="custom-control custom-radio mt-3">
          <input type="radio" (change)="changeRadioStatus()" id="noDelivery" class="custom-control-input"
            name="subscriptionCancelReasonRadio" formControlName="subscriptionCancelReasonRadio"
            value="I no longer want home delivery">
          <label class="custom-control-label" for="noDelivery">
            I no longer want home delivery
          </label>
        </div>

        <div class="custom-control custom-radio mt-3">
          <input type="radio" (change)="changeRadioStatus()" id="petPassedAway" class="custom-control-input"
            name="subscriptionCancelReasonRadio" formControlName="subscriptionCancelReasonRadio"
            value="My pet has passed away">
          <label class="custom-control-label" for="petPassedAway">
            My pet has passed away
          </label>
        </div>

        <div class="custom-control custom-radio mt-3">
          <input type="radio" (change)="changeRadioStatus()" id="other" name="subscriptionCancelReasonRadio"
            class="custom-control-input" formControlName="subscriptionCancelReasonRadio" value="OTHER">
          <label class="custom-control-label" for="other">
            Other
          </label>
        </div>
        <div class="mt-2  flex-column align-items-start me-5">
          <div class="custom-control mt-1 mb-0">
            <textarea id="cancelReason" rows="4" class="form-control border border-dark"
              aria-label="Cancel Reason"
              [hidden]="subscriptionCancelForm.controls['subscriptionCancelReasonRadio'].value!='OTHER'"
              formControlName="cancelReason"></textarea>
          </div>
        </div>

        <!-- <div
          *ngIf="(subscriptionCancelForm.controls['subscriptionCancelReasonRadio'].errors && subscriptionCancelForm.controls['subscriptionCancelReasonRadio'].dirty) || showRadioError"
          class="alert alert-danger mt-4 small text-center">
          Please select at least one option.
        </div> -->
      </form>
    </div>

  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-secondary ps-5 pe-5 btn-lg" id="cancelSubscriptionCancel"  (click)="closeCancelSubscription()">
      Cancel
    </button> -->
    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg ms-2" id="cancelSubscription" 
      (click)="checkCancelSubscription(subscription)">
      Submit
    </button>
</div>
  <div class="mt-2 mb-4 mx-4 px-3">
    <span class="text-black-50">
      For food-related questions or concerns, contact our Hills to Home Consumer Affairs at <a
      class="text-black-50" href="tel:1-800-445-5777">1-800-445-5777</a>. You may contact Hill's to Home Customer Support at <a
      class="text-black-50" href="tel:1-800-235-6877">1-800-235-6877</a> for all other questions.
    </span>
  </div>
</ng-template>

<ng-template #confirmHoldModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-body">
    <p class="m-4 h4 fw-normal">
      Are you sure you want to put subscription #{{subscription?.subscriptionId}} on hold?
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary ps-5 pe-5 btn-lg" (click)="dismissConfirm()">
      Cancel
    </button>
    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg ms-2" (click)="closeConfirm()">
      I'm Sure
    </button>
  </div>
</ng-template>

<ng-template #confirmReactivatelModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-body">
    <div class="modal-header">
      <div class="flex-grow-1 text-center">
      <h4 class="modal-title">Select Estimated Next Delivery Date</h4>
      </div>
      <button type="button" class="close mt-2" aria-label="Close" (click)="dismissConfirm()">
        <i class="fa fa-times"></i>
      </button>
    </div>
    <form [formGroup]="nextDeliveryDateSelectionform" autocomplete="off" class="mt-2 p-2 bg-white text-body">
      <div class="delivery-radio-list">
     
      <div class="custom-control custom-radio mt-3 px-2">
          <input type="radio"
                id="ship_now"
                class="custom-control-input"
                name="NextDeliveryDateSelectionRadio"
                formControlName="NextDeliveryDateSelectionRadio"
                value="Week_0" (change)="checkValue()">
          <label class="custom-control-label" for="ship_now">
            Deliver now
          </label>
      </div>
      <div class="custom-control custom-radio mt-3 px-2">
          <input type="radio"
                id="delay_1"
                name="NextDeliveryDateSelectionRadio"
                class="custom-control-input"
                formControlName="NextDeliveryDateSelectionRadio"
                value="WEEK_1" (change)="checkValue()">
          <label class="custom-control-label" for="delay_1">
            Deliver one week from today
          </label>
      </div>
      <div class="custom-control custom-radio mt-3 px-2">
        <input type="radio"
              id="delay_2"
              name="NextDeliveryDateSelectionRadio"
              class="custom-control-input"
              formControlName="NextDeliveryDateSelectionRadio"
              value="WEEK_2" (change)="checkValue()">
        <label class="custom-control-label" for="delay_2">
          Deliver two weeks from today
        </label>
      </div>
      <div class="custom-control custom-radio mt-3 px-2">
        <input type="radio"
              id="delay_3"
              name="NextDeliveryDateSelectionRadio"
              class="custom-control-input"
              formControlName="NextDeliveryDateSelectionRadio"
              value="WEEK_3" (change)="checkValue()">
        <label class="custom-control-label" for="delay_3">
          Deliver three weeks from today
        </label>
      </div>

      <div class="custom-control custom-radio mt-3 px-2">
        <input type="radio"
              id="delay_custom"
              name="NextDeliveryDateSelectionRadio"
              class="custom-control-input"
              formControlName="NextDeliveryDateSelectionRadio"
              value="WEEK_n" (change)="checkValue()">
        <label class="custom-control-label" for="delay_custom">
          Choose custom date:
        </label>
      </div>
      <!--------->
      <div class="custom-control mt-3 px-2">
        <div class="mt-1 mb-0 custom-box"
             [class.is-invalid]="nextDeliveryDateSelectionform.controls['deliveryDate'].dirty && nextDeliveryDateSelectionform.controls['deliveryDate'].invalid">
          <input [id]="'deliveryDate_'+i"
                 type="date"
                 class="form-control"
                 formControlName="deliveryDate"
                 min="{{maxDate}}"
                 onkeydown="return false">
        </div>
        <ul *ngIf="nextDeliveryDateSelectionform.controls['deliveryDate'].dirty &&
        nextDeliveryDateSelectionform.controls['deliveryDate'].errors &&
                   (nextDeliveryDateSelectionform.controls['deliveryDate'].errors['minDate'] ||
                   nextDeliveryDateSelectionform.controls['deliveryDate'].errors['onlyWeekDays'])"
            class="list-unstyled app-invalid-feedback small mt-2 mb-0">
          <li *ngIf="nextDeliveryDateSelectionform.controls['deliveryDate'].errors['minDate'] && nextDeliveryDateSelectionform.controls['deliveryDate'].errors['onlyWeekDays']">
            Please select a date on or after {{maxDate}}
          </li>
          <li *ngIf="nextDeliveryDateSelectionform.controls['deliveryDate'].errors['minDate'] && !(nextDeliveryDateSelectionform.controls['deliveryDate'].errors['onlyWeekDays'])">
            Please select a date on or after {{maxDate}}
          </li>
          <li *ngIf="nextDeliveryDateSelectionform.controls['deliveryDate'].errors['onlyWeekDays'] && !(nextDeliveryDateSelectionform.controls['deliveryDate'].errors['minDate'])">
            Please select week days only
          </li>
          <i class="app-bottom-arrow"></i>
        </ul>

      </div>
      <!------->
      <div *ngIf="(nextDeliveryDateSelectionform.controls['NextDeliveryDateSelectionRadio'].errors && nextDeliveryDateSelectionform.controls['NextDeliveryDateSelectionRadio'].dirty) || showRadioError" class="alert alert-danger mt-4 small text-center">
          Please select atleast one option.
      </div>
      </div>
  
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary ps-5 pe-5 btn-lg" (click)="dismissConfirm()">
      Cancel
    </button>
    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg ms-2" (click)="reactivateSubscription(subscriptionDetail);">
      Reactivate
    </button>
  </div>
</ng-template>

<!-- Skip Subscription Modal -->
<ng-template #skipSubscriptionModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-body skip-subscription-modal app-mobile-layout">
    <p class="m-4 h2 fw-bold">
      Skip Next Order
    </p>
    
    <div class="d-flex pe-3 pt-5 pb-5 ng-star-inserted" *ngFor="let entry of subscription?.cart?.entries; let i=index"
        [class.border-bottom]="i!==(subscription?.cart?.entries.length - 1)">
      <div class="d-flex align-items-center">
        <img src="{{entry.product.images | appImg}}" class="app-cart-img ng-star-inserted" *ngIf="entry.product.images" alt=" {{entry.product.name}}" />
        <div class="app-cart-img text-center" *ngIf="!entry.product.images">
          <i class="fas fa-image fa-4x"></i>
        </div>
        <div>
          {{entry.product.name}}
        </div>
      </div>
    </div>
    <div class="mt-2">
      <p class="m-4 h4 text-black-50 fw-bold">
        Your next estimated delivery will be {{subscription?.nextSkipDeliveryDate | date: 'MM/dd/yyyy'}}
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary ps-5 pe-5 btn-lg" id="skipOrderCancel" (click)="dismissConfirm()">
      Cancel
    </button>
    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg ms-2" id="skipOrder" (click)="confirmSkipSubscription(subscription)">
      I'm Sure
    </button>
  </div>
</ng-template>
<!-- Start ship now modal-->
<ng-template #shipNowModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-header">
    <div class="flex-grow-1 text-center">
      <h4 class="modal-title">Ship Now</h4>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="closeShipNowPopup()">
      <i class="fa fa-times"></i>
    </button>
  </div>
  <div class="modal-body ship-subscription-modal p-4">
    <div>
      <div *ngFor="let entry of subscription?.cart?.entries; let i=index" class="d-flex bg-white text-body">
        <div class="w-30">
          <div class="d-flex flex-column align-items-center">
            <img src="{{entry.product.images | appImg}}" *ngIf="entry.product.images" alt="{{entry.product.name}}" />
            <div class="app-cart-img text-center" *ngIf="!entry.product.images">
              <i class="fas fa-image fa-4x"></i>
            </div>
            <div class="text-black-50 small mt-3">
              SKU {{entry.product.code}}
            </div>
          </div>
        </div>
        <div class="w-70 ps-1">
          <div>{{entry.product.name}}</div>
          <div class="d-flex justify-content-between mt-3">
            <div class="d-flex align-items-center justify-content-between flex-grow-1 flex-wrap">
              <div>
                <div class="fw-bold">Price</div>
                <div *ngIf="entry.subscriptionUnit && entry.subscriptionUnit.deliveryFrequency !== DeliveryFrequencies.OneTime">
                  <span class="text-line-through">{{entry.basePrice.formattedValue}}</span>
                  <span class="app-title"> ${{(entry.totalPrice.value / entry.quantity | number: '.2-2')}}</span>
                </div>
                <div *ngIf="!entry.subscriptionUnit || entry.subscriptionUnit.deliveryFrequency === DeliveryFrequencies.OneTime">
                  {{entry.basePrice.formattedValue}}
                </div>
              </div>
              <div>
                <div class="fw-bold">Subtotal</div>
                <span>{{subscription?.cart?.totalPrice.formattedValue}}</span>
              </div>
              <div>
                <div class="fw-bold">Quantity</div>
                <span>{{entry.quantity}}</span>
              </div>
            </div>
          </div>
          <hr class="mt-3">
          <div class="pt-2">Your last scheduled delivery was {{subscription?.nextScheduledDeliveryDate | date: 'MM/dd/yyyy'}}.</div>
          <div class="pt-2">If you Ship Now, your next estimated delivery will be <span class="fw-bold">{{subscription?.nextShipNowDeliveryDate | date: 'MM/dd/yyyy'}}.</span></div>
          <div class="pt-3 pb-2 shipNow-popup-text">Are you sure you want to ship now?</div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary ps-5 pe-5 btn-lg" (click)="closeShipNowPopup()">
      No
    </button>
    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg ms-2" (click)="confirmShipNowSubscription(subscription)">
      Yes, Ship Now
    </button>
  </div>
</ng-template> 
<!-- End ship now modal-->
